.wrapper-popap-form {
  padding-bottom: 50px;
  @include respond-to('small') {
  }
}

.modal-open .modal {
  overflow-y: hidden; 
}

.popap-form {
  position: relative;
}

.modal-backdrop {
  &.in {
    opacity: 0.9;
  }
}

.form-contact{
  display: flex;
  justify-content: space-around;
}

.mob-close{
display: none;
@include respond-to('extra-small') {
  position: absolute;
  top: 15px;
  left: 15px !important;
  font-size: 30px;
  display: inline;
  z-index: 999;
  color: #000;
 }
}

.popap-form-btn-close {
  position: absolute;
  bottom: 230px;
  left: 450px;
  background-size: cover;
  width: 101px;
  height: 225px;
  z-index: -1;
  cursor: pointer; 
  filter: grayscale(1);
  background-image: url(../images/clo.png);
  @include respond-to('medium') {
    width: 55px;
    height: 120px;
    right: 0;
    left: auto;
   }

  & .close {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    opacity: 1;
    // filter: alpha(opacity=100);
  }
} 

.popap-form-image {

   margin-right: auto;
   text-align: center;
  padding-top: 15px;
  @include respond-to('medium') {
padding: 0 15px;
   }
}

.fade.in {
  opacity: 1;
  background: transparent;
}

.popap-form {
  max-width: 450px;
  overflow-y: scroll !important;
  width: 100%;
  border-right: 1px solid #000;
  min-height: 1080px;
  max-height: 1080px;
  background: #fff;
  padding: 0 20px 15px 0 ;
  border-radius: 3px;
  margin-right: auto;
  @include respond-to('large') {
    width: 100%;
  }
  @include respond-to('medium') {
    max-width: 350px;
    padding: 0 15px;
   }
  &__title {
    font-size: 42px;
    font-weight: 700;
    padding-bottom: 10px;
    color: #232684 !important;
    line-height: 1;
    padding-top: 50px;
    text-align: center;
    @include respond-to('medium') {
      font-size: 28px;
      padding-top: 30px;
    }
    @include respond-to('small') {
      padding: 30px 15px;
    }
  }
  &__sub-title {
    text-align: center;
    font-size: 21px;
    color: #232684;
    line-height: 1.2;
    margin-bottom: 30px;
    @include respond-to('medium') {
      font-size: 20px;
    }
    @include respond-to('small') {
      padding: 0 15px;
    }
  }
}

.form-flex{
  display: flex;
  align-items: flex-start;
}

.popap-form-row-first {
  margin-left: 0px;
  margin-right: 0px;
  @include respond-to('medium') {
    width: 100%;
   }
}

.form-image{
  width: 47%;
  text-align: end;
  @include respond-to('medium') {
   display: none;
  }
}

.popap-form-col-first {
  padding-right: 65px;
  padding-top: 30px;
  @include respond-to('medium') {
 padding: 10px 0;
   }
}

.popap-form-myform {
  &__input {
    width: 100%;
    height: 45px;
    border-bottom: 2px solid #c3c3c3 !important;
    border-radius: 0;
    padding: 0 30px 10px 30px;
    font-size: 18px;
    color: #a0a3a6;
    outline: none !important;
    border: none;
    margin-bottom: 40px;
    @include respond-to('medium') {
      margin-bottom: 20px;
     }
    &::placeholder {
      font-size: 18px;
      color: #808080;
      transition: opacity 0.4s;
    }
    &:focus {
      -webkit-outline: none;
      outline: none;
      &::placeholder {
        opacity: 0;
      }
    }
  }
  &__textarea {
    height: 120px;
    resize: none;
    @include respond-to('medium') {
      height: 60px;
     }
  }
  &__send {
    width: 100%;
    background: #242684 !important;
    border: 0;
    height: 50px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    &:focus {
      outline: none;
    }
    &:active {
      @include btn-effect-active;
    }
  }
  .form-group {
    margin-bottom: 11px;
  }
}

.popap-form-info {
  margin-bottom: 28px;
  &__title {
    font-size: 18px;
    color: #c3a179;
    line-height: 1;
    @include respond-to('small') {
      font-size: 16px;
    }
  }
  &__sub-title {
    font-size: 23px;
    font-weight: 800;
    color: #c3a179;
    line-height: 1;
    display: inline-block;
    @include respond-to('medium') {
      font-size: 20px;
    }
    @include respond-to('small') {
      font-size: 18px;
    }
    &:hover {
      color:blue;
    }
    & span{
      color: #fff;
    }
  }
  &__image {
    position: relative;
    background-repeat: no-repeat;
    display: inline-block;
    margin-top: 7px;
    margin-#{$left}: 15px;
    @include respond-to('small') {
      margin-top: 0;
    }
  }
  &__image-headphone {
    width: 46px;
    height: 47px;
    filter: grayscale(1);
  }
  &__image-email {
    width: 41px;
    height: 39px;
    filter: grayscale(1);
  }
  &__image-local {
    width: 37px;
    height: 43px;
    filter: grayscale(1);
  }
}

.popap-form-social {
  display: block;
}

.popap-form-social__box {
  width: 83px;
  position: relative;
  padding: 16px 0;
  display: inline-block;
  margin-#{$left}: 8px;
  @include respond-to('extra-small') {
    margin-#{$left}: 0px;
    width: 75px;
  }
}

.aside-panel__social-title {
  color: #c3a179;
  font-size: 16px;
  line-height: 1;
}

.popap-form-social__icon {
  margin-bottom: 8px;
  display: inline-block;
  & .blue {
    color:blue;
  }
}
.fa-2x {
  margin-#{$right}: 0;
}