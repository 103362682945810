.wrapper-banners {
	position: relative;
	width: 100%;
	height: 980px;
	overflow: hidden;
	@include respond-to('1550') {
		height: 850px;
	}
	@include respond-to('huge') {
		height: 750px;
	}
	@include respond-to('larger') {
		height: 650px;
	}
	@include respond-to('large') {
		height: 600px;
	}
	@include respond-to('1100') {
		height: 550px;
	}
	@include respond-to('medium') {
		height: 500px;
	}
	@include respond-to('small') {
		height: 400px;
	}
	@include respond-to('extra-small') {
		height: 320px;
	}
	@include respond-to('mobile-l') {
		height: 300px;
	}
	@include respond-to('mobile-m') {
		height: 250px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 980px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include respond-to('1550') {
		min-height: 850px;
	}
	@include respond-to('huge') {
		min-height: 750px;
	}
	@include respond-to('larger') {
		min-height: 650px;
	}
	@include respond-to('large') {
		min-height: 600px;
	}
	@include respond-to('1100') {
		min-height: 550px;
	}
	@include respond-to('medium') {
		min-height: 500px;
	}
	@include respond-to('small') {
		min-height: 400px;
	}
	@include respond-to('extra-small') {
		min-height: 320px;
	}
	@include respond-to('mobile-l') {
		min-height: 300px;
	}
	@include respond-to('mobile-m') {
		min-height: 250px;
	}
	&__text {
		position: absolute;
		left: 0;
		right: 0;
		top: 45%;
		transform: translateY(-50%);
		margin: 0 auto;
		max-width: 1230px;
		width: 100%;
		padding: 0 15px;
		@include respond-to('extra-small') {
			top: 50%;
		}
	}
}

.banner-title {
	padding-bottom: 20px;
	position: relative;
	text-align: center;
	font-size: 60px;
	font-weight: 400;
	color: $title;
	line-height: 0.9;
	text-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.2);
	@include respond-to('huge') {
		font-size: 50px;
	}
	@include respond-to('larger') {
		font-size: 40px;
	}
	@include respond-to('large') {
		font-size: 35px;
	}
	@include respond-to('medium') {
		font-size: 30px;
	}
	@include respond-to('small') {
		font-size: 25px;
	}
	& span {
		display: block;
		font-weight: 900;
	}
	&::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		bottom: -10px;
		width: 60px;
		height: 2px;
		background: $accent;
	}
}