.articles {
	&__item {
		border-bottom: 1px solid #d6d6d6;
		&:last-child {
			border-bottom: none;
		}
		&:first-child {
			.recommend-item {
				padding-top: 40px;
			}
		}
	}
}

.article-item {
	font-size: 0;
	padding: 35px 0;
	// @include respond-to('larger') {
	// 	padding: 50px 0;
	// }
	// @include respond-to('large') {
	// 	padding: 40px 0;
	// }
	@include respond-to('medium') {
		padding: 30px 0;
	}
	&__photo {
		display: inline-block;
		vertical-align: top;
		font-size: initial;
		padding-#{$left}: 10px;
		width: 22%;

		@include respond-to('large') {
			width: 22%;
		}
		@include respond-to('medium') {
			width: 26%;
		}
		@include respond-to('small') {
			width: 100%;
			border-#{$left}: none;
			margin-bottom: 0px;
			padding: 0;
			text-align: center;
		}
		& .about__img {
			position: relative;
			max-width: 220px;
			width: 100%;
			height: 200px;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.2);
			@include respond-to('small') {
				margin: 0 auto 20px;
			}
			& a {
				display: block;
				width: 100%;
				height: 100%;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				text-align: center;
				& img {
					margin-top: 45px;
					max-width: 90%;
				}
			}
			&::after {
				content: '';
				position: absolute;
				#{$left}: -35px;
				top: 50%;
				transform: translateY(-50%);
				width: 2px;
				height: 200px;
				background-color: $accent;
				@include respond-to('larger') {
					#{$left}: -30px;
				}
				@include respond-to('medium') {
					#{$left}: -20px;
				}
				@include respond-to('small') {
					display: none;
				}
			}
		}
	}
	&__info {
		width: 78%;
		display: inline-block;
		vertical-align: top;
		padding-#{$right}: 35px;
		font-size: initial;
		@include respond-to('large') {
			width: 78%;
			// padding-#{$right}: 40px;
		}
		@include respond-to('medium') {
			width: 74%;
			// padding-#{$right}: 50px;
		}
		@include respond-to('small') {
			width: 100%;
			text-align: center;
			padding-#{$right}: 0;
		}
	}
	&__title {
		position: relative;
		font-size: 25px;
		font-weight: 900;
		color: #000;
		line-height: 1;
		@include respond-to('small') {
			font-size: 20px;
		}
	}
	&__subtitle {
		position: relative;
		font-size: 20px;
		font-weight: 400;
		color: $accent;
		line-height: 1;
		@include respond-to('small') {
			font-size: 20px;
		}
	}
	&__text {
		margin-top: 20px;
		font-size: 18px;
		font-weight: 400;
		color: #000;
		line-height: 1.2;
		@include respond-to('medium') {
			font-size: 17px;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
		& p {}
	}
	&__btn {
		position: relative;
		display: inline-block;
		margin-top: 20px;
		@include link_on-hover($accent, $accent);
		font-size: 18px;
		font-weight: 700;
		padding-bottom: 8px;
		// border-bottom: 1px solid $accent;
		&::after {
			content: '';
			position: absolute;
			#{$right}: 0;
			bottom: 0;
			width: 44px;
			height: 2px;
			background-color: $accent;
		}
	}
}

.about__photo {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}