.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
	&_shadow {
		background-image: radial-gradient(circle, rgba(250, 250, 250, 1) 0%, rgba(250, 250, 250, 1) 80%, rgba(190, 190, 190, 1) 100%);
		background-position: top center;
		background-repeat: no-repeat;
	}
}

html {
	font-family: Assistant, sans-serif;
	font-weight: normal;
}

.container {
	width: 100%;
	max-width: 1230px;
}

.wrapper,
body.active {
	overflow: hidden;
}

.banner_scroll_body {
	width: 100%;
	height: 850px;
	overflow: hidden;
	#banners-scroll {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}
}

.main_block {
	width: 100%;
}

.slider_main_block {
	width: 100%;
	.slide {
		position: relative;
		.main {
			object-fit: cover;
		}
		.container {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 3;
			padding-top: 376px;
			.title {
				width: 100%;
				text-align: center;
				color: #ffffff;
				font-size: 60px;
				b {
					font-weight: 800;
				}
			}
		}
	}
}

.responsive_wrapper {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	z-index: 100;
	transition: .3s;
	transform: translateX(110%);
	background: #000;
	overflow-y: auto;
	.inner {
		width: 100%;
		position: relative;
		padding: 30px 30px;
		padding-bottom: 80px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.close_menu {
			position: absolute;
			top: 10px;
			right: 20px;
			color: #fff;
			svg {
				width: 15px;
			}
		}
		nav {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 30px;
			a {
				color: #fff;
				font-size: 20px;
				font-weight: 600;
				position: relative;
				padding: 0;
				margin-bottom: 30px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.responsive_wrapper.active {
	transform: none;
}

.main_content_wrap {
	width: 100%;
	// background: url('../images/bg.png') top center no-repeat;
	background-image: url('../images/main-bg.jpg');
	background-position: top center;
	background-repeat: no-repeat;
	// background-size: cover;
	position: relative;
	z-index: 4;
	@include respond-to('small') {
		background-repeat: repeat;
	}
	@include respond-to('extra-small') {
		background-repeat: no-repeat;
		background-size: cover;
	}
}

.about_wrap {
	width: 100%;
	padding-bottom: 110px;
	position: relative;
  &_2{
    &:before{
      display: none !important;
    }
  }
	&:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		// bottom: 0;
		// left: 50%;
		// transform: translateX(-50%);
		// width: 1920px;
		margin: 0 auto;
		z-index: 3;
		height: 125px;
		// top: -85px;
		bottom: 100%;
		background-image: url('../images/top-decor.png');
		background-position: top center;
		background-repeat: no-repeat;
		// background-size: cover;
		// background: url('../images/back4.png') top center no-repeat;
		@include respond-to('extra-small') {
			display: none;
		}
	}
	.container {
		max-width: 1274px;
	}
	.top_cont {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 65px;
		@include respond-to('medium') {
			padding-top: 15px;
		}
		@include respond-to('extra-small') {
			padding-top: 25px;
		}
    & > h1{
      margin: 5px 0;
      @include respond-to('extra-small') {
        font-size: 26px !important;
      }
    }
		figure {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			&:before {
				content: "";
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: -20px;
				width: 44px;
				height: 2px;
				background-color: #2e3094;
				z-index: 2;
			}
		}
		p {
			width: 100%;
			max-width: 780px;
			margin-top: 62px;
			color: #000000;
			font-size: 18px;
			font-weight: 400;
			line-height: 25px;
			text-align: center;
			margin-bottom: 10px;
      & > iframe{
        @include respond-to('extra-small') {
          width: 300px !important;
          margin: 0 auto;
        }
      }
		}
		a {
			color: #2e3094;
			font-size: 18px;
			font-weight: 800;
			position: relative;
			&:hover {
				text-decoration: none;
			}
			&:before {
				content: "";
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: -20px;
				width: 44px;
				height: 2px;
				background-color: #2e3094;
				z-index: 2;
			}
		}
	}
	.slider_wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 130px;
		.slider {
			width: 100%;
			max-width: calc(1056px + 100px);
			margin-left: -50px;
			margin-right: -50px;
			.slide {
				margin: 0 50px;
				figure {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 88px;
					// & img {
					// 	filter: grayscale(100);
					// 	transition: filter 0.2s ease;
					// 	&:hover {
					// 		filter: grayscale(0);
					// 	}
					// }
				}
			}
		}
	}
}

.advantages_wrap {
	width: 100%;
	background: url('../images/back5.jpg') top center no-repeat;
	background-size: cover;
	padding-top: 122px;
	padding-bottom: 148px;
	ul {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-flow: row wrap;
		li {
			width: 25%;
			display: flex;
			flex-direction: column;
			align-items: center;
			@include respond-to('small') {
				width: 50%;
			}
			figure {
				display: flex;
				height: 76px;
				justify-content: center;
				align-items: center;
				margin-bottom: 20px;
			}
			p {
				text-align: center;
				color: #ffffff;
				font-size: 30px;
				font-weight: 800;
				position: relative;
				@include respond-to('small') {
					font-size: 25px;
				}
				&:before {
					content: "";
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					bottom: -20px;
					width: 44px;
					height: 2px;
					background-color: #2e3094;
					z-index: 2;
				}
			}
		}
	}
}

.projects_wrap {
	width: 100%;
	padding-top: 100px;
	h3 {
		margin-bottom: 74px;
	}
	.col-md-12 {
		padding-left: 0;
		padding-right: 0;
	}
	ul {
		width: 100%;
		display: flex;
		// justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;
		li {
			width: 25%;
			a {
				width: 100%;
				height: 100%;
				position: relative;
				display: flex;
        padding: 20px;
        & .title-hov{
         left: 50%;
          position: absolute;
          top: 50%;
          width: 100%;
          transform: translate(-50%, -50%);
 
    & .title-hov__title{
      text-align: center;
      color: #fff;
      font-weight: 800;
      width: 100%;
      text-transform: uppercase;
      text-shadow: 4px 3px 0 rgba(0, 0, 0, .6);
      font-size: 30px;
      margin-bottom: 40px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -19px;
        width: 44px;
        height: 2px;
        background-color: #fff;
        z-index: 2;
      }
      @include respond-to('small') {
        font-size: 22px;
        padding: 0 20px;
      }
    }
    & .sml{
      text-align: center;
      color: #fff;
      font-size: 18px;
      font-weight: 800;
      width: 100%;
      text-transform: uppercase;
    }
        }
				&:hover {
          & .title-hov{
            display: none;
          }
					.overlay {
						opacity: 1;
						visibility: visible;
            & .title{
              
            }
					}
          
					& img {
						filter: grayscale(0%);
					}
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					filter: grayscale(0%) !important;
					transition: filter 0.2s ease;
				}
				.overlay {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					z-index: 3;
					transition: .3s;
					background-color: rgba(0, 0, 0, .2);
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					opacity: 0;
					visibility: hidden;
					p {
						text-align: center;
						color: #fff;
						font-size: 18px;
						font-weight: 800;
						width: 100%;
						text-transform: uppercase;
						&.title {
							font-size: 30px;
							margin-bottom: 40px;
							position: relative;
							&:before {
								content: "";
								position: absolute;
								left: 50%;
								transform: translateX(-50%);
								bottom: -19px;
								width: 44px;
								height: 2px;
								background-color: #fff;
								z-index: 2;
							}
						}
					}
				}
			}
		}
	}
}

h3 {
	width: 100%;
	position: relative;
	text-align: center;
	color: #000000;
	font-size: 30px;
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: -0.9px;
	&:before {
		content: "";
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -14px;
		width: 44px;
		height: 2px;
		background-color: #2e3094;
		z-index: 2;
	}
}

.articles_wrap {
	width: 100%;
	padding: 80px 0;
	h3 {
		margin-bottom: 121px;
	}
	ul {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-flow: row wrap;
		li {
			display: flex;
			flex-flow: column nowrap;
			width: calc(33.33% - 20px);
			box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
			background-color: #ffffff;
			justify-content: space-between;
			@include respond-to('1100') {
				width: calc(50% - 20px);
				margin-bottom: 40px;
			}
			img {
				width: 100%;
				height: 260px;
				object-fit: cover;
			}
			.text {
				width: 100%;
				padding: 30px 15px;
				display: flex;
				flex-direction: column;
				align-items: center;
				p {
					width: 100%;
					text-align: center;
					color: #000000;
					font-size: 18px;
					font-weight: 400;
					line-height: 20px;
					text-transform: uppercase;
					&.title {
						font-size: 20px;
						letter-spacing: -0.6px;
						position: relative;
						margin-bottom: 48px;
						font-weight: 800;
						&:before {
							content: "";
							position: absolute;
							left: 50%;
							transform: translateX(-50%);
							bottom: -16px;
							width: 44px;
							height: 2px;
							background-color: #2e3094;
							z-index: 2;
						}
					}
				}
			}
			a {
				width: 100%;
				color: #ffffff;
				font-size: 18px;
				font-weight: 800;
				line-height: 0.76px;
				text-transform: uppercase;
				letter-spacing: -0.54px;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 60px;
				background-color: #2e3094;
			}
		}
	}
}

// MEDIA QUERIES

@media screen and (max-width: 1199px) {
	.banner_scroll_body {
		height: 500px;
	}
	.slider_main_block .slide {
		.main {
			width: 100%;
			height: 600px;
		}
	}
	.slider_main_block .slide .container {
		padding-top: 177px;
	}
	.slider_main_block .slide .container .title {
		font-size: 40px;
	}
	.about_wrap:before {
		// height: 130px;
		// top: -130px;
	}
	.about_wrap .slider_wrap .slider {
		width: calc(100% + 40px - 50px);
		margin-left: -20px;
		margin-right: -20px;
		.slide {
			margin: 0 20px;
		}
	}
	.projects_wrap ul li {
		width: 33.3333%;
	}
}

@media screen and (max-width: 991px) {
	.about_wrap .slider_wrap {
		margin-top: 55px;
	}
	.about_wrap {
		padding-bottom: 40px;
	}
	.advantages_wrap {
		padding: 40px 0;
	}
	.projects_wrap {
		padding-top: 40px;
	}
	.projects_wrap h3 {
		margin-bottom: 40px;
	}
	.projects_wrap ul li {
		width: 50%;
	}
	.articles_wrap {
		padding-top: 40px;
		padding-bottom: 40px;
		h3 {
			margin-bottom: 40px;
		}
	}
	.articles_wrap ul li {
		// width: calc(33.333% - 5px);
	}
}

@media screen and (max-width: 768px) {
	.advantages_wrap ul {
		flex-direction: row;
		align-items: center;
		li {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.articles_wrap ul {
		flex-direction: column;
		align-items: center;
		li {
			width: 100%;
			max-width: 500px;
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.articles_wrap ul li img {
		height: auto;
	}
	.slider_main_block .slide .container .title {
		font-size: 30px;
	}
}


@media screen and (max-width: 600px) {
	.projects_wrap ul li {
		width: 100%;
	}
}
