.global-btn-price {
  position: fixed;
  bottom: 300px;
  left: 0;
  width: 100px;
  height: 100px;
  cursor: pointer;
  z-index: 100;
  @include respond-to('small') {
    width: 90px; 
    height: 90px;
    font-size: 18px;
    left: 0;
    font-weight: 400;
  }
}

.text-popup{
  position: absolute;
    color: #fff;
    left: -8px;
    top: 131%;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    font-size: 20px;
    font-weight: 700;
    width: 100%;
@include respond-to('small') {
  display: none;
}
}