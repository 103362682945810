.header-desktop {
	pointer-events: all;
	@include respond-to('medium') {
		display: none;
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		display: block;
		background-color: #fff;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 2px;
		float: $left;
		& > img {
			max-width: 122px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: $accent;
			font-size: 32px;
		}
	}
}

header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 5;
	transition: .3s;
	padding-top: 0px;
	background-image: url(../images/header-bg.png);
	background-position: center bottom;
	height: 334px;
	pointer-events: none;
	@include respond-to('medium') {
		pointer-events: all;
		background: none;
	}
	&.active {
		height: 80px;
		@include respond-to('medium') {
			height: 70px;
		}
	}
	.container {
		position: relative;
		z-index: 3;
	}
	.figure {
		position: absolute;
		opacity: 0;
		visibility: hidden;
		transition: .3s;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		max-width: 29%;
		height: 86px;
	}
	.inside_cont {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	nav {
		width: 100%;
		max-width: 822px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		a {
			color: #ffffff;
			font-size: 20px;
			font-weight: 600;
			@include link_on-hover(#fff, $accent);
			border-bottom: 2px solid transparent;
			&.active {
				// color: $accent;
				border-bottom: 2px solid $accent;
			}
		}
	}
	.logo {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
	}
	.logo2 {
		display: none;
	}
	&.active {
		padding: 5px 0;
		background-color: rgba(0, 0, 0, 0.8);
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
		@include respond-to('medium') {
			padding: 0;
		}
		.logo {
			display: none;
			padding-top: 0px;
			margin-top: 0;
		}
		.logo2 {
			display: flex;
		}
		.figure {
			opacity: 1;
			visibility: visible;
		}
		.mob_menu__button span {
			background-color: #fff;
		}
	}
}

.main-navi {
	max-width: 100%;
	width: 100%;
	display: block;
	padding: 0;
	transition: padding 0.2s ease;
	padding: 26px 0 0 0;
	& > ul {
		float: left;
		display: inline-block;
		vertical-align: top;
		list-style: none;
		padding: 0;
		margin: 0;
		transition: all 0.2s ease;
		& > li {
			float: $right;
			margin: 0;
			position: relative;
			padding: 0 9px 0;
			@include respond-to('large') {
				padding: 14px 8px 16px;
			}
			&:first-child {
				padding-#{$right}: 0;
				& a {
					// padding-#{$right}: 0;
				}
			}
			&:last-child {
				&::after {
					display: none;
				}
			}
			& > a {
				display: block;
				padding: 3px 1px 2px;
				font-size: 20px;
				font-weight: 600;
				color: #fff;
				transition: all 0.2s ease;
				@include respond-to('larger') {
					font-size: 18px;
				}
				@include respond-to('large') {
					font-size: 17px;
				}
				&:hover {
					text-decoration: none;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					color: $accent;
				}
            }
            padding-bottom: 25px;
      &:hover{
        & > ul{
          visibility: visible;
          opacity: 1;
        }
      }
      & > ul{/* level 2 */
        display: block;
        padding: 0; 
        margin: 0;
        list-style: none;
        position: absolute;
        top: 37px;
        #{$right}: 0;
        background-color: #2e3095;
        transition: all 0.4s;
        visibility: hidden;
        opacity: 0;
        & > li{
          &:not(:last-child){
			border-bottom: 1px solid #0a3162;
          }
          & > a{
            font-size: 18px;
            color: #fff;
            display: block;
            position: relative;
            white-space: nowrap;
            padding: 2px 30px;
            transition: all 0.3s;
            &:hover{
			  background-color: #5d5fa9;
              text-decoration: none;
            }
          }
        }
      }/* END level 2 */
		}
	}
}


@media screen and (max-width: 1440px) {
	header .figure {
		max-width: 25%;
	}
}

@media screen and (max-width: 1199px) {
	header nav {
		max-width: 689px;
	}
	header .logo {
		display: none;
		&.logo2 {
			display: flex;
		}
	}
}

@media screen and (max-width: 991px) {
	header nav {
		display: none;
	}
	.mob_menu__button {
		display: block;
	}
	header .figure {
		display: none;
	}
}