.wrap-content {
	padding: 90px 0;
	@include respond-to('larger') {
		padding: 70px 0;
	}
	@include respond-to('large') {
		padding: 60px 0;
	}
	@include respond-to('1100') {
		padding: 40px 0;
	}
	@include respond-to('medium') {
		padding: 30px 0;
	}
	@include respond-to('small') {
		padding: 25px 0;
	}
}


.main-title {
	position: relative;
	font-size: 30px;
	line-height: 1;
	color: #000;
	margin: 0 0 60px;
	padding-bottom: 10px;
	text-align: center;
	font-weight: 900;
	@include respond-to('1100') {
		margin: 0 0 40px;
	}
	@include respond-to('medium') {
		margin: 0 0 30px;
	}
	@include respond-to('small') {
		margin: 0 0 25px;
	}
	&::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		bottom: 0;
		width: 44px;
		height: 2px;
		background-color: $accent;
	}
}

.wrap-all-content-not-banner,
.padding-top-catalog-item {
	margin-top: 180px;
	@include respond-to('medium') {
		margin-top: 80px;
	}
}

.hr-content {
	border-top: 1px solid #d9d9d9;
	margin: 0 0 20px;
}

.content-text {
	h2,
	h3 {
		font-size: 25px;
		font-weight: 600;
		line-height: 1.2;
		color: #000;
		margin: 20px 0 20px;
		@include respond-to('large') {
			font-size: 23px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	p {
		font-size: 18px;
		font-weight: 400;
		color: #000;
		line-height: 1.2;
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}


