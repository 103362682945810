.footer {
	width: 100%;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
	padding-top: 80px;
	background-image: url(../images/footer-bg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	z-index: 6;
	.inside_cont {
		display: flex;
		flex-direction: column;
		align-items: center;
		.top_cont {
			width: 100%;
			margin-bottom: 40px;
			p {
				width: 100%;
				color: #000000;
				font-size: 18px;
				text-align: center;
				&.title {
					font-size: 30px;
					font-weight: 800;
				}
			}
		}
		form {
			width: 100%;
			padding-bottom: 40px;
			border-bottom: 1px solid #d5d5d5;
			display: flex;
			justify-content: space-between;
			align-items: center;
			input {
				width: 20%;
				background-color: #ffffff;
				height: 50px;
				border: none;
				padding-right: 26px;
				padding-left: 6px;
				color: #9d9d9d;
				font-size: 18px;
				letter-spacing: -0.36px;
				&::placeholder {
					color: #9d9d9d;
					font-size: 18px;
					letter-spacing: -0.36px;
				}
			}
			button {
				color: #ffffff;
				font-size: 18px;
				font-weight: 800;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				max-width: 130px;
				height: 50px;
				background-color: #2e3094;
				border: none;
			}
		}
		.main_cont {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding-top: 60px;
			padding-bottom: 60px;
			border-bottom: 1px solid #c2c2c2;
			.right_side {
				width: 100%;
				max-width: 180px;
				.title {
					width: 100%;
					color: #333333;
					font-size: 20px;
					font-weight: 800;
					margin-bottom: 28px;
				}
				ul {
					width: 100%;
					li {
						width: 100%;
						color: #000000;
						font-size: 16px;
						font-weight: 600;
						line-height: 25px;
						b {
							color: #a3a3a3;
						}
					}
				}
			}
			.left_side {
				width: calc(100% - 180px);
				padding-right: 165px;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				.column {
					width: 100%;
					max-width: 200px;
					p {
						width: 100%;
						color: #000000;
						font-size: 20px;
						font-weight: 600;
						padding-bottom: 24px;
						border-bottom: 1px solid #c2c2c2;
						margin-bottom: 30px;
					}
					ul {
						width: 100%;
						li {
							width: 100%;
							a {
								color: #848484;
								font-size: 16px;
								line-height: 30px;
							}
						}
					}
				}
			}
		}
		.bottom {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 13px 0;
		}
	}
}




@media screen and (max-width: 991px) {
	.footer .inside_cont .main_cont .left_side {
		padding-right: 30px;
	}
	.footer {
		padding-top: 40px;
	}
}

@media screen and (max-width: 768px) {
	.footer .inside_cont form {
		flex-direction: column;
		align-items: center;
		input {
			width: 100%;
			max-width: 560px;
			margin-bottom: 10px;
		}
	}
	.footer .inside_cont .main_cont {
		flex-direction: column;
		align-items: center;
		padding-bottom: 30px;
		.right_side {
			margin-bottom: 15px;
		}
		.left_side {
			width: 100%;
			padding-right: 0;
			flex-direction: column;
			align-items: center;
			.column {
				margin-bottom: 20px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.footer .inside_cont .main_cont .left_side .column p {
		padding-bottom: 15px;
		margin-bottom: 15px;
	}
}
