.services {
	font-size: 0;
	text-align: center;
	margin: 0 -37px;
	@include respond-to('large') {
		margin: 0 -15px;
	}
	&__item {
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 33.33%;
		margin-bottom: 30px;
		padding: 30px 37px 85px;
		font-size: initial;
		border-bottom: 1px solid #e6e6e6;
		height: 585px;
		overflow: hidden;
		@include respond-to('large') {
			padding: 20px 15px 85px;
			margin-bottom: 20px;
		}
		@include respond-to('medium') {
			width: 50%;
		}
		@include respond-to('550') {
			width: 100%;
			height: auto;
		}
	}
}

.wrap-service-box {
	display: block;
	@include link_no-hover($text);
	&:hover {
		.service-box-image__item {
			transform: scale(1.07);
		}
	}
}

.service-box-image {
	max-width: 350px;
	width: 100%;
	height: 300px;
	overflow: hidden;
	margin: 0 auto 25px;
	@include respond-to('small') {
		height: 250px;
	}
	@include respond-to('extra-small') {
		margin: 0 auto 15px;
	}
	&__item {
		background-position: center center;
		background-size: cover;
		width: 100%;
		height: 300px;
		transition: all 0.6s;
		@include respond-to('small') {
			height: 250px;
		}
	}
}

.service-box-data {
	&__title {
		font-size: 25px;
		font-weight: 900;
		color: #000;
		line-height: 1;
		max-height: 60px;
		overflow: hidden;
		@include respond-to('medium') {
			font-size: 25px;
		}
		@include respond-to('550') {
			max-height: unset;
		}
		@include respond-to('extra-small') {
			font-size: 22px;
		}
	}
	&__content {
		margin: 18px 0 25px;
		padding: 0 20px;
		max-height: 105px;
		overflow: hidden;
		@include respond-to('550') {
			max-height: unset;
		}
		@include respond-to('extra-small') {
			margin: 12px 0 18px;
		}
		&.text {
			color: #000;
		}
	}
	&__btn {
		// position: relative;
		position: absolute;
		bottom: 50px;
		left: 0;
		right: 0;
		margin: 0 auto;
		display: inline-block;
		margin-top: 0px;
		@include link_on-hover($accent, $accent);
		font-size: 18px;
		font-weight: 700;
		padding-bottom: 8px;
		// border-bottom: 1px solid $accent;
		&::after {
			content: '';
			position: absolute;
			#{$right}: 0;
			#{$left}: 0;
			margin: 0 auto;
			bottom: 0;
			width: 44px;
			height: 2px;
			background-color: $accent;
		}
	}
}