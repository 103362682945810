.gallery-item-wr2 {
	float: $right;
	width: 25%;
	height: 470px;
	padding: 5px;
	@include respond-to('larger') {
		height: 350px;
	}
	// @include respond-to('large') {
	// 	height: 450px;
	// }
	@include respond-to('medium') {
	width: 33.33%;
	}
	@include respond-to('small') {
		width: 50%;
	}
	@include respond-to('extra-small') {
		width: 100%;
		height: 250px;
	}
	// &:nth-child(5n+3),
	// &:nth-child(5n+4),
	// &:nth-child(5n+5) {
	// 	width: 33.33%;
	// 	height: 450px;
	// 	@include respond-to('huge') {
	// 		height: 400px;
	// 	}
	// 	@include respond-to('larger') {
	// 		height: 350px;
	// 	}
	// 	@include respond-to('larger') {
	// 		height: 300px;
	// 	}
	// 	@include respond-to('small') {
	// 		width: 50%;
	// 		height: 270px;
	// 	}
	// 	@include respond-to('extra-small') {
	// 		width: 100%;
	// 		height: 250px;
	// 	}
	// }
}

.gallery-item2 {
	position: relative;
	display: block;
	height: 100%;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
  background-size: 65%;
  background-color: white;
	&:hover {
		.gallery-item2__img {
			// transform: scale3d(1.1, 1.1, 1.1);
		}
		.gallery-item2__overlay {
			opacity: 1;
		}
	}
	&__img {
		opacity: 0;
		visibility: hidden;
		display: block;
		max-width: 100%;
		height: 100%;
		width: 100%;
		transform: scale3d(1, 1, 1);
		transition: transform 0.15s ease 0s;
	}
	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: $accent, $alpha: 0.8);
		opacity: 0;
		transition: opacity 0.15s ease 0s;
	}
	&__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		& i {
			font-size: 40px;
			color: #fff;
		}
	}
	&__text {
		position: absolute;
		bottom: 35px;
		left: 50%;
		display: block;
		width: 70%;
		transform: translateX(-50%);
		text-align: center;
		color: #ffffff;
		font-size: 18px;
		font-weight: 300;
	}
}