.investors {
	box-shadow: 0px 0 45px 0px rgba(0, 0, 0, 0.2);
	text-align: center;
	&__title {
		text-align: center;
	}
	&__slider {
		padding: 55px 0;
		@include respond-to('small') {
			padding: 30px 0;
		}
	}
	&__slide-item-wrap {
		display: none;
		position: relative;
		height: 130px;
		line-height: 130px;
		padding: 0 5px;
		@include respond-to('small') {
			height: 120px;
			line-height: 120px;
		}
	}
	&__slide-item {
		display: inline-block;
		width: 100%;
		height: 100%;
		&:active,
		&:focus,
		&:hover {
			outline: none;
			.part-logo {
				opacity: 1;
			}
			.part-logo-white {
				opacity: 0;
			}
		}
		&:first-child {
			display: block;
		}
		& img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			max-height: 98%;
			max-width: 98%;
			height: auto;
		}
	}
}

#clients-slide {
	& .slick-arrow {
		top: 50%;
	}
	& .slick-prev {
		#{$right}: -65px;
		#{$left}: auto;
		@include respond-to('larger') {
			#{$right}: 15px;
		}
		@include respond-to('small') {
			#{$right}: 5px;
		}
	}
	& .slick-next {
		#{$left}: -65px;
		#{$right}: auto;
		@include respond-to('larger') {
			#{$left}: 15px;
		}
		@include respond-to('small') {
			#{$left}: 5px;
		}
	}
}
